// Colors
$alaska-blue: #1a3b58;
$dark-blue: #00416b;
$mist-gray: #c8c9c7;
$dark-gray: #212223;
$light-gray: #f9f9f9;
$blue-link: #0074c8;

// Dimensions
$max-width: 360px;
$iframe-width: $max-width - 36px;
$default-font-size: 16px;


// Fonts
@font-face {
 font-family: ASCircular;
 src: url(../../assets/ASCircularWeb-Book.woff2) format('woff2');
}

// Global styles
.content-wrapper {
  width: $max-width;
  margin: 0 auto;
  font-family: ASCircular, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: $dark-gray;
  font-size: $default-font-size;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    margin-top: 60px;
  }
}

.section-title {
  color: $dark-blue;
  padding-top: 5px;
  font-size: 26px;
}


.hidden {
  display: none !important;
}

.bold {
  font-weight: 700 !important;
}

.button {
  width: calc(100% - 32px);
  height: 48px;
  display: block;
  margin: 16px;
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 1px $blue-link;
  cursor: pointer;
  font-size: $default-font-size;
  &.primary {
    background-color: $blue-link;
    color: white;
    &:hover {
      box-shadow: rgba(1, 66, 106, 0.4);
      background-color: #176cff;
    }
  }
  &.secondary {
    background-color: white;
    color: $blue-link;
  }
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    color: #58606c;
    border-color: #f7f7f7;
    background-color: #f7f7f7;
    &:hover {
      box-shadow: transparent;
      color: #58606c;
      border-color: #f7f7f7;
      background-color: #f7f7f7;
    }
  }
}

