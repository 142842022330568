$message-bar-height: 54px;
$light-green: #def279;
$error-red: #df0b37;

.message-bar {
    min-height: $message-bar-height;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.11);
    background-color: $light-green;
    padding: 8px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    line-height: normal;
    &.error {
      background-color: $error-red;
      color: white;
    }
    & ul {
      text-indent: 0;
      padding-inline-start: 16px;
      margin: 0;
    }
    & li:not(:last-of-type) {
      padding-bottom: 8px;
    }
  }