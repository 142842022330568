$light-blue: #00c7e6;
$header-height: 74px;

#header {
  height: $header-height;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background-color: white;
  #logo {
    border-right: 1px solid #c8c9c7;
    padding-right: 7px;
    display: inline-block;
  }
  .site-title {
    color: $light-blue;
    font-size: 32px;
    line-height: 49px;
    padding-left: 7px;
    vertical-align: top;
  }
}