@import '../seat-upgrade.scss';

.summary {
  padding: 16px;
  box-sizing: border-box;
  .info {
    padding: 16px 0;
  }
  .line-item {
    line-height: 24px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .line-item:not(:last-of-type) {
    padding-bottom: 8px;
  }
  .primary {
    flex: 1 100%;
  }
}
