$million-miler: #7b7b7b;

// Loading animation variables
$loading-animation-duration: 1.2;
$dot-1-delay-percentage: 0.11;
$dot-2-delay-percentage: $dot-1-delay-percentage * 2;

#content-loader {
    height: 10vh;
    min-height: 60px;
}

.loader {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    div {
        width: 12px;
        height: 12px;
        background-color: $million-miler;
        border-radius: 100%;
        display: inline-block;
        animation: bounce-delay ($loading-animation-duration * 1s) infinite ease-in-out both;
        margin: 0 3px;
    }

    .dot-2 {
        animation-delay: $dot-1-delay-percentage * 1s;
    }

    .dot-3 {
        animation-delay: $dot-2-delay-percentage * 1s;
    }
}

@keyframes bounce-delay {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}