body {
  background-color: var(--auro-color-background-lightest);
  padding: 20px 16px;

  &.hidden {
    display: none;
  }
}

a {
  color: var(--auro-color-text-link-on-light);

  &:hover {
    text-decoration: none;
  }
}
